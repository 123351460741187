// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, primaryColor, unit, unitTRBL, white } from '@zego/theme';

import H2 from '@zego/components/atoms/H2';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import Article from '@zego/components/molecules/Article';
import CardLayout from '@zego/components/molecules/CardLayout';
import ContentContainer from '@zego/components/SCContentContainer';

import B2CCar from '@zego/static/images/b2c-car.jpg';
import ZegoTeam2019 from '@zego/static/images/zego-team-2019.jpg';
import ZegoTeamLicence from '@zego/static/images/zego-team-licence.jpg';

interface Article {
  id: string;
  title: string;
  imageUrl: string;
  description: string;
  link: {
    href: string;
    title: string;
  };
}

const ARTICLES: Article[] = [
  {
    id: 'about',
    title: 'Our Story',
    imageUrl: ZegoTeamLicence,
    description:
      'It started with us powering opportunities for self-employed drivers and riders. It’s led us to powering opportunities for whole fleets across Europe.',
    link: {
      href: '/about-us/',
      title: 'Discover more',
    },
  },
  {
    id: 'careers',
    title: 'Work with Us',
    imageUrl: ZegoTeam2019,
    description:
      'Zego thrives on curiosity, unconventional thinking and diverse experience. People who push for progress day-in day-out. People like you.',
    link: {
      href: '/careers/',
      title: 'Join us',
    },
  },
  {
    id: 'blog',
    title: 'The Zego Blog',
    imageUrl: B2CCar,
    description:
      'Find more information on how to get the most out of your insurance whether your’re a self-employed driver or rider or managing an entire fleet.',
    link: {
      href: '/blog/',
      title: 'Read our latest articles',
    },
  },
];

export default function About(): JSX.Element {
  return (
    <WellContainer>
      <H2
        css={{
          fontSize: 30,
          textAlign: 'center',
          [BREAKPOINTS.medium]: { fontSize: 40 },
        }}
      >
        Learn about Zego
      </H2>
      <Spacer height={unit(5)} />
      <ContentContainer
        css={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CardLayout>
          {ARTICLES.map(article => (
            <Article
              key={article.id}
              {...article}
              theme="light"
              imageRatio={2}
            />
          ))}
        </CardLayout>
      </ContentContainer>
    </WellContainer>
  );
}

/**
 * Styled components
 */

const WellContainer = styled(View)({
  backgroundColor: primaryColor(3),
  color: white,
  padding: unitTRBL(6, 0, 6, 0),
});
