import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { useFlags } from 'launchdarkly-react-client-sdk';

import products from '@zego/content/products-dropdown.json';
import { BREAKPOINTS, unit } from '@zego/theme';
import useTracking from '@zego/tracking/useTracking';

import View from '@zego/components/atoms/View';
import DropDown from '@zego/components/molecules/DropDown';

//TODO: This should come from backend viewer.country.productCategories
// but there's no B2B vs B2C split

interface Option {
  label: string;
  value: string;
  name: string;
}

interface SplitProductDropDownProps {
  trackingPrefix?: string;
}

export default function SplitProductDropDown({
  trackingPrefix,
}: SplitProductDropDownProps): JSX.Element {
  const [active, setActive] = useState(0);
  const [openState, setOpenState] = useState([false, false]);
  const handleMenuStateChanged = (dropDownIndex: number) => (
    open: boolean,
  ): void => {
    setOpenState(existingState => {
      const newState = [...existingState];
      newState[dropDownIndex] = open;
      return newState;
    });
  };

  const router = useRouter();
  const { trackEvent } = useTracking();
  const isAnyOpen = openState.some(Boolean);

  const handleProductSelected = ({
    selectedItem: { value: product, name: productTitle },
  }): void => {
    trackEvent(
      `${
        trackingPrefix && `${trackingPrefix} `
      }Product Selector - Select product`,
      {
        productTitle,
      },
    );

    const url = `/${product}/`;
    router.prefetch(url);

    // Wait for animation to complete before performing navigation
    setTimeout(() => {
      router.push(url);
    }, 250);
  };

  const { vanOwnGoodsLandingPage = true } = useFlags();
  const DRIVER_PRODUCTS = vanOwnGoodsLandingPage
    ? products.DRIVER_PRODUCTS_WITH_VAN_OWN_GOODS
    : products.DRIVER_PRODUCTS;

  return (
    <View
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        position: 'relative',
        zIndex: 1,
        [BREAKPOINTS.medium]: {
          display: 'flex',
          flexDirection: 'row',
        },
      }}
    >
      <DropDown<Option>
        options={DRIVER_PRODUCTS}
        buttonCss={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          [BREAKPOINTS.smallOnly]: {
            borderBottomLeftRadius: isAnyOpen ? 0 : unit(1),
          },
        }}
        isActive={active === 0}
        onActivate={(): void => setActive(0)}
        onMenuStateChanged={handleMenuStateChanged(0)}
        onChange={handleProductSelected}
      >
        For Drivers
      </DropDown>
      <DropDown<Option>
        options={products.FLEET_PRODUCTS}
        buttonCss={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          [BREAKPOINTS.smallOnly]: {
            borderBottomRightRadius: isAnyOpen ? 0 : unit(1),
          },
        }}
        isActive={active === 1}
        onActivate={(): void => setActive(1)}
        onMenuStateChanged={handleMenuStateChanged(1)}
        onChange={handleProductSelected}
      >
        For Fleets
      </DropDown>
    </View>
  );
}
