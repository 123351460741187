// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  BREAKPOINTS,
  disabledColor,
  getColorTint,
  primaryColor,
  unit,
} from '@zego/theme';

import H2 from '@zego/components/atoms/H2';
import RegularText from '@zego/components/atoms/RegularText';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import ButtonUnstyled from '@zego/components/button/ButtonUnstyled';
import ContentModal from '@zego/components/ContentModal';
import Hairline from '@zego/components/Hairline';
import ContentContainer from '@zego/components/SCContentContainer';

import BoltLogo from '@zego/static/svgs/partners/bolt-logo.svg';
import DeliverooLogo from '@zego/static/svgs/partners/deliveroo.svg';
import FreeNowLogo from '@zego/static/svgs/partners/free-now.svg';
import JustEatLogo from '@zego/static/svgs/partners/just-eat-2021.svg';
import OlaLogo from '@zego/static/svgs/partners/ola.svg';
import UberEatsLogo from '@zego/static/svgs/partners/uber-eats-homepage.svg';
import UberLogo from '@zego/static/svgs/partners/uber-homepage.svg';
import StuartImage from '@zego/static/svgs/StuartBlue.svg';

interface PartnerLogo {
  id: string;
  alt: string;
  imageUrl: string;
}

const PARTNERS_COPY = {
  title: 'Powering opportunities with partners',
  partners: [
    {
      id: 'uber',
      alt: 'Uber logo',
      imageUrl: UberLogo,
    },
    {
      id: 'deliveroo',
      alt: 'Insider logo',
      imageUrl: DeliverooLogo,
    },
    {
      id: 'uber-eats',
      alt: 'Insider logo',
      imageUrl: UberEatsLogo,
    },
    {
      id: 'just-eat',
      alt: 'Insider logo',
      imageUrl: JustEatLogo,
    },
    {
      id: 'ola',
      alt: 'Insider logo',
      imageUrl: OlaLogo,
    },
    {
      id: 'stuart',
      alt: 'Stuart logo',
      imageUrl: StuartImage,
    },
    {
      id: 'free-now',
      alt: 'Free Now logo',
      imageUrl: FreeNowLogo,
    },
    {
      id: 'bolt',
      alt: 'Bolt logo',
      imageUrl: BoltLogo,
    },
  ],
};

const MORE_WORK_PROVIDERS = [
  'Absolutely Couriers',
  'Apache Pizza',
  'Deliveroo',
  'GoGetters',
  'Gophr',
  'Just Eat',
  'One Delivery',
  'Stuart',
  'Uber Eats',
  'Vromo',
  'AutoCab',
];

export default function Partners(): JSX.Element {
  const [isModalOpen, handleModalOpen] = useState(false);

  return (
    <Container>
      <Title>{PARTNERS_COPY.title}</Title>
      <Spacer height={unit(3)} />
      <View css={{ flexDirection: 'column', textAlign: 'center' }}>
        <PartnerLayout>
          {PARTNERS_COPY.partners.map((partner: PartnerLogo) => (
            <LogoImage
              key={partner.id}
              src={partner.imageUrl}
              alt={partner.alt}
            />
          ))}
        </PartnerLayout>
        <Button onClick={() => handleModalOpen(true)}>And many more</Button>
        {/* @ts-expect-error: BC2-452 added this placeholder error description */}
        <ContentModal
          modalIsOpen={isModalOpen}
          handleModalClose={() => handleModalOpen(!isModalOpen)}
        >
          <RegularText
            css={{
              textAlign: 'center',
              paddingBottom: unit(1),
              color: disabledColor(10),
              fontWeight: 'bold',
            }}
          >
            Other partners
          </RegularText>
          <Hairline />
          <Spacer height={unit(3)} />
          <View css={{ columnCount: 2, columnGap: 50 }}>
            {MORE_WORK_PROVIDERS.map(partner => (
              <RegularText
                key={partner}
                css={{ color: disabledColor(10), fontSize: 14 }}
              >
                {partner}
              </RegularText>
            ))}
          </View>
        </ContentModal>
      </View>
    </Container>
  );
}

/**
 * Styled components
 */

const Container = styled(ContentContainer)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [BREAKPOINTS.medium]: {
    paddingTop: unit(10),
    paddingBottom: unit(10),
    flexDirection: 'row',
  },
  paddingTop: unit(5),
  paddingBottom: unit(5),
});

const Button = styled(ButtonUnstyled)({
  color: primaryColor(9),
  padding: unit(1),
});

const Title = styled(H2)({
  marginRight: unit(5),
  fontSize: 30,
  color: getColorTint('grey', 11),
  lineHeight: '34px',
  [BREAKPOINTS.medium]: { fontSize: 40, lineHeight: '49px' },
});

const PartnerLayout = styled(View)({
  display: 'grid',
  gridTemplateColumns: `repeat(3 ,1fr)`,
  alignItems: 'center',
  justifyItems: 'center',
  paddingBottom: unit(5),
  gap: unit(2),
  [BREAKPOINTS.medium]: {
    gap: unit(4),
  },
});

const LogoImage = styled('img')({
  maxHeight: 20,
  maxWidth: '100%',
  objectFit: 'contain',
  [BREAKPOINTS.medium]: {
    maxHeight: 50,
  },
});
