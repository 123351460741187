import React from 'react';

const Tick = ({ color }) => (
  <svg width="12" height="10" xmlns="http://www.w3.org/2000/svg">
    <desc>Tick</desc>
    <path
      d="M.75 4.955l-.795.795 3.397 3.398c.22.22.576.22.796 0l7.897-7.898-.795-.795-7.5 7.5-3-3z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default Tick;
