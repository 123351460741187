import * as React from 'react';

import { BREAKPOINTS, unit, white } from '@zego/theme';

import View from '@zego/components/atoms/View';
import FlexRow from '@zego/components/molecules/FlexRow';
import CheckList from '@zego/components/organisms/CheckList';
import ContentContainer from '@zego/components/SCContentContainer';
import StaticImage from '@zego/components/StaticImage';

import AppsImage from '@zego/static/images/ios-app-and-fleet.jpg';

type Benefit = string;

interface WhyZegoCopy {
  title: React.ReactNode;
  imageUrl: string;
  imageAlt: string;
  benefits: Benefit[];
}

const WHY_ZEGO_COPY: WhyZegoCopy = {
  title: (
    <>
      Insurance that saves you{' '}
      <br
        css={{ display: 'none', [BREAKPOINTS.medium]: { display: 'inline' } }}
      />
      time and money
    </>
  ),
  imageUrl: AppsImage,
  imageAlt: 'Fleet management and Zego Sense iOS app',
  benefits: [
    'Best in market prices',
    'Simple to buy and use',
    'Flexible policies available from 1 hour to 1 year',
  ],
};

export default function WhyZego(): JSX.Element {
  return (
    <View
      css={{
        backgroundSize: 'cover',
        backgroundColor: white,
        [BREAKPOINTS.medium]: {
          paddingTop: unit(10),
          paddingBottom: unit(10),
        },
        paddingTop: unit(5),
        paddingBottom: unit(5),
      }}
    >
      <ContentContainer>
        <FlexRow
          css={{ [BREAKPOINTS.smallOnly]: { flexDirection: 'column' } }}
        >
          <CheckList
            title={WHY_ZEGO_COPY.title}
            items={WHY_ZEGO_COPY.benefits.map(benefit => ({
              title: benefit,
            }))}
          />

          <View
            css={{
              width: '100%',
              [BREAKPOINTS.medium]: { width: '50%', marginLeft: unit(2) },
            }}
          >
            <StaticImage
              src={WHY_ZEGO_COPY.imageUrl}
              alt={WHY_ZEGO_COPY.imageAlt}
              ratio={1.415}
              css={{ width: '100%', display: 'block' }}
            />
          </View>
        </FlexRow>
      </ContentContainer>
    </View>
  );
}
