import React from 'react';

import { BREAKPOINTS, primaryColor, unit, unitTRBL, white } from '@zego/theme';

import H2 from '@zego/components/atoms/H2';
import View from '@zego/components/atoms/View';
import SplitProductDropDown from '@zego/components/molecules/SplitProductDropDown';
import TrustPilotSummary from '@zego/components/molecules/TrustPilotSummary';
import ContentContainer from '@zego/components/SCContentContainer';

const GET_QUOTE_COPY = {
  title: 'Get a quote in minutes...',
  trustPilot: {
    score: 4.6,
    reviews: 2324,
  },
};

interface GetQuoteProps {
  trackingPrefix?: string;
}

export default function GetQuote({
  trackingPrefix,
}: GetQuoteProps): JSX.Element {
  return (
    <View
      css={{
        backgroundColor: primaryColor(11),
        padding: unitTRBL(6, 0, 6, 0),
        [BREAKPOINTS.medium]: {
          padding: unitTRBL(12, 0, 12, 0),
        },
      }}
    >
      <ContentContainer>
        <View
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: unit(2),
            justifyContent: 'center',
            alignItems: 'center',
            [BREAKPOINTS.medium]: { gridTemplateColumns: '3fr 2fr' },
            [BREAKPOINTS.large]: { gridTemplateColumns: '4fr 3fr' },
          }}
        >
          <H2
            css={{
              color: white,
              fontSize: 30,
              [BREAKPOINTS.medium]: { fontSize: 40 },
            }}
          >
            {GET_QUOTE_COPY.title}
          </H2>
          <SplitProductDropDown
            css={{ [BREAKPOINTS.smallOnly]: { flex: 1 } }}
            trackingPrefix={trackingPrefix}
          />
          <TrustPilotSummary
            score={GET_QUOTE_COPY.trustPilot.score}
            reviews={GET_QUOTE_COPY.trustPilot.reviews}
            css={{ [BREAKPOINTS.smallOnly]: { gridArea: '2' } }}
          />
        </View>
      </ContentContainer>
    </View>
  );
}
