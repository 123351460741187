// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, getColorTint, unit, unitTRBL } from '@zego/theme';

import H2 from '@zego/components/atoms/H2';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import FlexRow from '@zego/components/molecules/FlexRow';
import ContentContainer from '@zego/components/SCContentContainer';
import StaticImage from '@zego/components/StaticImage';

import CarsImage from '@zego/static/images/cars.jpg';
import RisingLine from '@zego/static/svgs/rising-line-green-dot.svg';

const HIGH_IMPACT_COPY = {
  title: 'Over 200,000 vehicles insured',
  content:
    'Since our inception in 2016, Zego has protected over 200,000 vehicles for thousands of businesses, from sole traders to fleets to global corporates.',
  imageUrl: CarsImage,
  imageAlt: 'High angle photo of cars on street showing insured icon',
};

export default function HighImpact(): JSX.Element {
  return (
    <View
      css={{
        padding: unitTRBL(4, 0, 4, 0),
        backgroundColor: getColorTint('grey', 2),
        [BREAKPOINTS.medium]: {
          padding: unitTRBL(7, 0, 7, 0),
        },
      }}
    >
      <ContentContainer>
        <FlexRow
          css={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: 'column',
            [BREAKPOINTS.medium]: { flexDirection: 'row' },
            [BREAKPOINTS.large]: { alignItems: 'center' },
          }}
        >
          <MainImage
            src={HIGH_IMPACT_COPY.imageUrl}
            alt={HIGH_IMPACT_COPY.imageAlt}
            ratio={1.33}
          />
          <Spacer mobileHeight={unit(4)} />
          <View
            css={{
              [BREAKPOINTS.medium]: {
                width: '45%',
              },
            }}
          >
            <Title>{HIGH_IMPACT_COPY.title}</Title>
            <p
              css={{
                color: getColorTint('grey', 8),
                fontSize: 16,
              }}
            >
              {HIGH_IMPACT_COPY.content}
            </p>
            <RisingLineGraphic
              src={RisingLine}
              alt="Graphic of a curved line with a small circle at the end"
            />
          </View>
        </FlexRow>
      </ContentContainer>
    </View>
  );
}

/**
 * Styled components
 */

const RisingLineGraphic = styled('img')({
  marginTop: unit(3),
  [BREAKPOINTS.smallOnly]: {
    width: '100%',
    marginLeft: -150,
  },
  [BREAKPOINTS.medium]: { marginTop: unit(2) },
});

const Title = styled(H2)({
  color: getColorTint('grey', 10),
  lineHeight: '42px',
  fontSize: 30,
  [BREAKPOINTS.medium]: { fontSize: 40 },
});

const MainImage = styled(StaticImage)({
  width: '100%',
  borderRadius: unit(1),
  [BREAKPOINTS.medium]: {
    width: '42%',
  },
});
