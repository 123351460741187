import React from 'react';

import { ClassNames } from '@emotion/core';
import { Trans } from '@lingui/macro';

import useModal from '@zego/hooks/useModal';
import { disabledColor, unit, white } from '@zego/theme';

import View from '@zego/components/atoms/View';
import UnstyledButton from '@zego/components/button/UnstyledButton';

export default function ContentModal({
  id,
  modalIsOpen,
  handleModalClose,
  children,
}) {
  const [Modal] = useModal(modalIsOpen);

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          onRequestClose={handleModalClose}
          overlayClassName={css({
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            zIndex: 1100,
            '&.ReactModal__Overlay--after-open': {
              transition: 'all 200ms ease-in-out',
              opacity: 1,
            },
            '&.ReactModal__Overlay--before-close': {
              opacity: 0,
            },
          })}
          className={css({
            position: 'absolute',
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: `calc(100% - 70px)`,
            maxWidth: '350px',
            height: 'auto',
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: '13px',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            background: white,
            paddingTop: 20,
            paddingBottom: 20,
            opacity: 1,
          })}
        >
          <View id={id}>{children}</View>
          <View
            css={{
              marginTop: unit(4),
              textAlign: 'center',
            }}
          >
            <UnstyledButton
              onClick={handleModalClose}
              css={{
                fontWeight: 500,
                fontSize: 14,
                cursor: 'pointer',
                color: `${disabledColor(7)}`,
              }}
            >
              <Trans id="Modal / Close">Close</Trans>
            </UnstyledButton>
          </View>
        </Modal>
      )}
    </ClassNames>
  );
}
