import React from 'react';
import { graphql } from 'react-relay';

import { HomepageGbSCQueryResponse } from '@zego-types/HomepageGbSCQuery.graphql';
import useNavigation from '@zego/hooks/useNavigation';
import BaseLayout from '@zego/layouts/BaseLayout';
import withData from '@zego/lib/withData';
import Hero from '@zego/pageContainers/SeriesCRedesign/Homepage/sections/Hero';

import LogoutConfirmationNotification from '@zego/components/molecules/LogoutConfirmationNotification';
import GetQuote from '@zego/components/organisms/GetQuote';
import SCContentContainer from '@zego/components/SCContentContainer';
import withFeatureFlags from '@zego/components/withFeatureFlags';

import About from './sections/About';
import HighImpact from './sections/HighImpact';
import Partners from './sections/Partners';
import Press from './sections/Press';
import WhyZego from './sections/WhyZego';

const TRACKING_PREFIX = 'Website Homepage';

export function HomepageGbSC({
  viewer,
}: HomepageGbSCQueryResponse): JSX.Element {
  const navigation = useNavigation();
  const loggedOut = navigation.query?.loggedOut;
  return (
    <>
      {loggedOut && <LogoutConfirmationNotification />}
      <BaseLayout
        viewer={viewer}
        title="Zego - where insurance makes sense."
        description="Zego is a 'New Mobility' insurance provider, powering opportunities for modern business by saving time and money. Start saving - get your quote today."
        headerIsInitiallyLightOnDark={false}
        unshiftHeader={true}
        headerContainer={SCContentContainer}
        upshiftContent={true}
      >
        <Hero />
        <Press />
        <WhyZego />
        <GetQuote trackingPrefix={TRACKING_PREFIX} />
        <HighImpact />
        <Partners />
        <GetQuote trackingPrefix={TRACKING_PREFIX} />
        <About />
      </BaseLayout>
    </>
  );
}

export default withData(withFeatureFlags(HomepageGbSC), {
  query: graphql`
    query HomepageGbSCQuery($country: String!) {
      viewer {
        ...CustomerReviews_viewer
        ...BaseLayout_viewer @arguments(country: $country)
        ...HomeProductFinder_viewer @arguments(country: $country)
        ...ProductSelector_viewer @arguments(country: $country)
      }
    }
  `,
  getVariables: ({ query }) => {
    return {
      country: query.country,
    };
  },
});
