// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, getColorTint, unit, unitTRBL } from '@zego/theme';

import View from '@zego/components/atoms/View';
import FlexRow from '@zego/components/molecules/FlexRow';

import BusinessInsiderLogo from '@zego/static/svgs/press/business-insider-mono.svg';
import TechCrunchLogo from '@zego/static/svgs/press/tech-crunch-mono.svg';
import TheGuardianLogo from '@zego/static/svgs/press/the-guardian-mono.svg';
import TheTelegraphLogo from '@zego/static/svgs/press/the-telegraph-mono.svg';

/**
 * Copy
 */
const PRESS_COPY = {
  outlets: [
    {
      id: 'tech-crunch',
      alt: 'Tech Crunch logo',
      imageUrl: TechCrunchLogo,
    },
    {
      id: 'business-insider',
      alt: 'Insider logo',
      imageUrl: BusinessInsiderLogo,
    },
    {
      id: 'guardian',
      alt: 'The Guardian logo',
      imageUrl: TheGuardianLogo,
    },
    {
      id: 'telegraph',
      alt: 'The Telegraph logo',
      imageUrl: TheTelegraphLogo,
    },
  ],
};

/**
 * Main component
 */

export default function Press(): JSX.Element {
  return (
    <View
      css={{
        backgroundColor: getColorTint('grey', 2),
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <FlexLayout>
        {PRESS_COPY.outlets.map(outlet => (
          <img
            key={outlet.id}
            src={outlet.imageUrl}
            alt={outlet.alt}
            css={{
              width: 80,
              maxHeight: 30,
              [BREAKPOINTS.medium]: { width: 150 },
            }}
          />
        ))}
      </FlexLayout>
    </View>
  );
}

/**
 * Styled components
 */

const FlexLayout = styled(FlexRow)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: unit(2),
  alignItems: 'center',
  justifyItems: 'center',
  padding: unit(2),
  maxWidth: unit(30),
  [BREAKPOINTS.medium]: {
    padding: unitTRBL(3, 10, 3, 10),
    display: 'flex',
    flexWrap: 'initial',
    maxWidth: 'unset',
  },
  [BREAKPOINTS.large]: {
    maxWidth: unit(150),
  },
});
