import React from 'react';

import TrustpilotColor from '@zego/static/svgs/TrustpilotColor';
import { unit, unitTRBL, white } from '@zego/theme';

import View from '@zego/components/atoms/View';
import FlexRow from '@zego/components/molecules/FlexRow';

interface TrustPilotSummaryProps {
  reviews: number;
  score: number;
}

export default function TrustPilotSummary({
  reviews,
  score,
  ...rest
}: TrustPilotSummaryProps): JSX.Element {
  let formattedReviews;
  try {
    formattedReviews = Intl.NumberFormat('en-gb').format(reviews);
  } catch (e) {
    console.error(e);
    formattedReviews = reviews;
  }
  return (
    <FlexRow
      css={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'max-content',
        backgroundColor: 'rgb(55, 25, 135)',
      }}
      {...rest}
    >
      <TrustpilotColor
        color={white}
        css={{ height: 20, marginTop: -5, width: 85, marginRight: unit(1) }}
      />
      <View css={{ color: white, fontSize: 14, fontWeight: 100 }}>
        {formattedReviews} reviews{' '}
        <span css={{ padding: unitTRBL(0, 1, 0, 1) }}>|</span> TrustScore{' '}
        {score}
      </View>
    </FlexRow>
  );
}
