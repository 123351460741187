import React from 'react';

import { BREAKPOINTS, unit } from '@zego/theme';

import H3 from '@zego/components/atoms/H3';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import SplitProductDropDown from '@zego/components/molecules/SplitProductDropDown';
import TrustPilotSimple from '@zego/components/molecules/TrustPilotSimple';
import BaseHero, { HeroProps } from '@zego/components/organisms/Hero';

import heroImageLarge from '@zego/static/images/hero-homepage-large.png';
import heroImageMedium from '@zego/static/images/hero-homepage-medium.png';

const HERO_COPY: HeroProps = {
  kicker: 'Where insurance makes sense',
  title: (
    <>
      For vehicles.{' '}
      <br
        css={{
          display: 'none',
          [BREAKPOINTS.medium]: { display: 'block' },
          [BREAKPOINTS.large]: { display: 'none' },
        }}
      />
      For work.{' '}
      <br
        css={{
          display: 'block',
          [BREAKPOINTS.smallOnly]: { display: 'none' },
        }}
      />
      For less.
    </>
  ),
  backgroundImage: [heroImageMedium, heroImageLarge],
  backgroundImageRatio: [750 / 562, 1166 / 1536],
  footer: <TrustPilotSimple reviews={2243} />,
};

export default function Hero(): JSX.Element {
  return (
    <BaseHero {...HERO_COPY}>
      <H3>Get a quote</H3>
      <Spacer height={unit(3)} />
      <View css={{ [BREAKPOINTS.medium]: { maxWidth: unit(49) } }}>
        <SplitProductDropDown trackingPrefix="Website Homepage" />
      </View>
    </BaseHero>
  );
}
